export default [
  {
    path: "/",
    name: "home",
    component: () => import("../../components/management/index"),
    redirect: "/home/homepage",
    children: [
      {
        path: "/home/homepage",
        name: "homepage",
        component: () => import("../../views/homepage/index"),
      },
      {
        path: "/home/productionService",
        name: "productionService",
        component: () => import("../../views/productionService/index"),
      },
      {
        path: "/home/resourcesService",
        name: "resourcesService",
        component: () => import("../../views/resourcesService/index"),
      },
      {
        path: "/home/financialService",
        name: "financialService",
        component: () => import("../../views/financialService/index"),
      },
      {
        path: "/home/financialOther",
        name: "financialOther",
        component: () => import("../../views/quickLink/financialOther"),
      },

      // 快速链接
      {
        path: "/quickLink/landTrust",
        name: "landTrust",
        component: () => import("../../views/quickLink/landTrust"),
      },
      {
        path: "/quickLink/agriService",
        name: "agriService",
        component: () => import("../../views/quickLink/agriService"),
      },
      {
        path: "/quickLink/agriServiceDetail",
        name: "agriServiceDetail",
        component: () => import("../../views/quickLink/agriServiceDetail"),
      },
      {
        path: "/quickLink/machineryService",
        name: "machineryService",
        component: () => import("../../views/quickLink/machineryService"),
      },
      {
        path: "/quickLink/machineryServiceDetail",
        name: "machineryServiceDetail",
        component: () => import("../../views/quickLink/machineryServiceDetail"),
      },
      {
        path: "/quickLink/technologyService",
        name: "technologyService",
        component: () => import("../../views/quickLink/technologyService"),
      },
      {
        path: "/quickLink/AgricultureRelatedSubsidies",
        name: "AgricultureRelatedSubsidies",
        component: () =>
          import("../../views/quickLink/AgricultureRelatedSubsidies"),
      },
      {
        path: "/quickLink/accountingService",
        name: "accountingService",
        component: () => import("../../views/quickLink/accountingService"),
      },
      {
        path: "/quickLink/peasantCircle",
        name: "peasantCircle",
        component: () => import("../../views/quickLink/peasantCircle"),
      },

      {
        path: "/home/login",
        name: "login",
        component: () => import("../../views/login/index"),
      },
      {
        path: "/home/register",
        name: "register",
        component: () => import("../../views/login/register"),
      },
      {
        path: "/home/chooseRole",
        name: "chooseRole",
        component: () => import("../../views/login/chooseRole"),
      },
      {
        path: "/home/register1",
        name: "register1",
        component: () => import("../../views/login/registerPage/nh"),
      },
      {
        path: "/home/register2",
        name: "register2",
        component: () => import("../../views/login/registerPage/jtjjzh"),
      },
      {
        path: "/home/register3",
        name: "register3",
        component: () => import("../../views/login/registerPage/xxjjzz"),
      },
      {
        path: "/home/resetPwd",
        name: "resetPwd",
        component: () => import("../../views/login/forgetPwd"),
      },
      {
        path: "/home/exchange",
        name: "exchange",
        component: () => import("../../views/exchange/index"),
      },
      {
        path: "/home/projectDetail",
        name: "projectDetail",
        component: () => import("../../views/exchange/detail"),
      },
      {
        path: "/home/announcement",
        name: "announcement",
        component: () => import("../../views/announcement/index"),
      },
      {
        path: "/home/demandDetail",
        name: "demandDetail",
        component: () => import("../../views/announcement/demandDetail"),
      },
      {
        path: "/home/successDetail",
        name: "successDetail",
        component: () => import("../../views/announcement/successDetail"),
      },
      {
        path: "/home/finance",
        name: "finance",
        component: () => import("../../views/finance/index"),
      },
      {
        path: "/home/LoanDetails",
        name: "LoanDetails",
        component: () => import("../../views/finance/LoanDetails"),
      },
      {
        path: "/home/bankList",
        name: "bankList",
        component: () => import("../../views/finance/bankList"),
      },
      {
        path: "/home/productionChange",
        name: "productionChange",
        component: () => import("../../views/productionChange/index"),
      },
      {
        path: "/home/service",
        name: "service",
        component: () => import("../../views/service/index"),
      },
      {
        path: "/home/download",
        name: "download",
        component: () => import("../../views/download/index"),
      },
      {
        path: "/home/developmentManage",
        name: "developmentManage",
        component: () => import("../../views/developmentManagement/index"),
      },
      {
        path: "/home/news",
        name: "newsPage",
        component: () => import("../../views/developmentManagement/newsPage"),
      },
      {
        path: "/home/aboutUs",
        name: "aboutUs",
        component: () => import("../../views/aboutUs/index"),
      },
      {
        path: "/home/questionDetail",
        name: "questionDetail",
        component: () => import("../../views/aboutUs/questionDetail.vue"),
      },
      {
        path: "/home/Chat",
        name: "Chat",
        component: () => import("../../views/chat/chat.vue"),
      },
      {
        path: "/home/application",
        name: "application",
        component: () => import("../../views/finance/application.vue"),
      },

      //这是新集采
      {
        path: "/home/newPurchase",
        name: "newPurchase",
        component: () => import("../../views/exchange/newPurchase.vue"),
      },
      //集采中标信息公示
      {
        path: "/home/CallForBids",
        name: "CallForBids",
        component: () => import("../../views/exchange/CallForBids.vue"),
      },
      //预览
      {
        path: "/home/Preview",
        name: "Preview",
        component: () => import("../../views/exchange/Preview.vue"),
      },
      //付款页面
      {
        path: "/home/pay",
        name: "pay",
        component: () => import("../../views/exchange/pay.vue"),
      },
      //集采中标信息公示
      {
        path: "/home/TenderAnnouncement",
        name: "TenderAnnouncement",
        component: () => import("../../views/exchange/TenderAnnouncement.vue"),
      },
      //公告公示列表
      {
        path: "/home/PublicAnnouncement",
        name: "PublicAnnouncement",
        component: () => import("../../views/PublicAnnouncement/index.vue"),
      },
      //查看招标公告
      {
        path: "/home/LookTenderNotice",
        name: "LookTenderNotice",
        component: () =>
          import("../../views/homepage/components/LookTenderNotice.vue"),
      },
      //查看中标的公告
      {
        path: "/home/WinTheBid",
        name: "WinTheBid",
        component: () =>
          import("../../views/homepage/components/WinTheBid.vue"),
      },
      //查看认领
      {
        path: "/home/RenZheng",
        name: "RenZheng",
        component: () => import("../../views/renzheng/index"),
      },
      //查看贷款详情
      {
        path: "/home/wxf",
        name: "wxf",
        component: () => import("../../views/wxf/index"),
      },
      //贷款申请
      {
        path: "/home/dksq",
        name: "dksq",
        component: () => import("../../views/wxf/dksq"),
      },
      {
        path: "/home/dklb",
        name: "dklb",
        component: () => import("../../views/wxf/dklb"),
      },
      //流转审批详情
      {
        path: "/home/lzsp",
        name: "lzsp",
        component: () => import("../../views/lzsp/index"),
      },
      {
        path: "/home/xctfw",
        name: "xctfw",
        component: () => import("../../views/wxf/xctfw"),
      },
      {
        path: "/home/xctfllist",
        name: "xctfllist",
        component: () => import("../../views/wxf/xctfllist"),
      },
      {
        path: "/home/btsp",
        name: "btsp",
        component: () =>
          import("../../views/memberCenter/snbt/snbtbl/components/btsp.vue")
      },
    // 农户补贴申请
    {
      path: "/btsqtx/grainSubsidy",
      name: "grainSubsidy",
      component: () => import("../../views/btsqtx/grainSubsidy"),
    },
    // 社会化服务补贴
    {
      path: "/btsqtx/socialAllowance",
      name: "socialAllowance",
      component: () => import("../../views/btsqtx/socialAllowance"),
    },
    // 农机购置补贴填写
    {
      path: "/btsqtx/farmMachinery",
      name: "farmMachinery",
      component: () => import("../../views/btsqtx/farmMachinery"),
    },
    //农机购置详情



      //   // 要删的页面--以下动态生成
      // {
      //   path: "/memberCenter",
      //   name: "memberCenter",
      //   component: () => import("../../views/memberCenter/index"),
      // redirect: "/memberCenter",
      // children: [

      //   {
      //     path: "/user/landTrust",
      //     name: "userLandTrust",
      //     component: () => import("../../views/memberCenter/tggl/reportMsg"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   //
      //   //提示页面
      //   {
      //     path: "/user/supplyInfo",
      //     name: "supplyInfo",
      //     component: () => import("../../views/memberCenter/tggl/supplyInfo"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   {
      //     path: "/user/damendInfo",
      //     name: "damendInfo",
      //     component: () => import("../../views/memberCenter/tggl/damendInfo"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   //

      //   {
      //     path: "/memberCenter/njfw/yczdjl",
      //     name: "yczdjl",
      //     component: () => import("../../views/memberCenter/njfw/yczdjl/index"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   {
      //     path:"/memberCenter/tggl/wytg",
      //     name:"wytg",
      //     component: () => import("../../views/memberCenter/tggl/wytg/index"),
      //     meta:{
      //       Author: true,
      //     },
      //   },
      //   {
      //     path:"/memberCenter/tggl/wyjg",
      //     name:"wyjg",
      //     component: () => import("../../views/memberCenter/tggl/wyjg/index"),
      //     meta:{
      //       Author: true,
      //     },
      //   },
      //   {
      //     path: "/memberCenter/tggl/yjgtd",
      //     name: "yjgtd",
      //     component: () => import("../../views/memberCenter/tggl/yjgtd/index"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   {
      //     path: "/memberCenter/tggl/sctgtd",
      //     name: "sctgtd",
      //     component: () => import("../../views/memberCenter/tggl/sctgtd/index"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   {
      //     path: "/memberCenter/tggl/jgts",
      //     name: "jgts",
      //     component: () => import("../../views/memberCenter/tggl/jgts/index"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   {
      //     path: "/memberCenter/tggl/tgts",
      //     name: "tgts",
      //     component: () => import("../../views/memberCenter/tggl/tgts/index"),
      //     meta: {
      //       Author: true,
      //     },
      //   },

      //   {
      //     path: "/memberCenter/zyzcgl/fbcqxx",
      //     name: "fbcqxx",
      //     component: () => import("../../views/memberCenter/zyzcgl/fbcqxx/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/zyzcgl/zyzclzts",
      //     name: "zyzclzts",
      //     component: () => import("../../views/memberCenter/zyzcgl/zyzclzts/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/zyzcgl/ybmxm",
      //     name: "ybmxm",
      //     component: () => import("../../views/memberCenter/zyzcgl/ybmxm/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/zyzcgl/jjzxm",
      //     name: "jjzxm",
      //     component: () => import("../../views/memberCenter/zyzcgl/jjzxm/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/zyzcgl/jjjgck",
      //     name: "jjjgck",
      //     component: () => import("../../views/memberCenter/zyzcgl/jjjgck/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/user/bzjsh",
      //     name: "bzjsh",
      //     component: () => import("../../views/memberCenter/zyzcgl/bzjsh/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/user/ggsjgl",
      //     name: "ggsjgl",
      //     component: () => import("../../views/memberCenter/zyzcgl/ggsjgl/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/user/yhzhgl",
      //     name: "yhzhgl",
      //     component: () => import("../../views/memberCenter/zyzcgl/yhzhgl/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/rzsq/wdrz",
      //     name: "wdrz",
      //     component: () => import("../../views/memberCenter/rzsq/wdrz/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/rzsq/dydb",
      //     name: "dydb",
      //     component: () => import("../../views/memberCenter/rzsq/dydb/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/rzsq/zydb",
      //     name: "zydb",
      //     component: () => import("../../views/memberCenter/rzsq/zydb/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/rzsq/bzdb",
      //     name: "bzdb",
      //     component: () => import("../../views/memberCenter/rzsq/bzdb/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/xxgl/fbtztg",
      //     name: "fbtztg",
      //     component: () => import("../../views/memberCenter/xxgl/fbtztg/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/xxgl/scflwj",
      //     name: "scflwj",
      //     component: () => import("../../views/memberCenter/xxgl/scflwj/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/xxgl/znxx",
      //     name: "znxx",
      //     component: () => import("../../views/memberCenter/xxgl/znxx/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/memberCenter/zhgl/smrz",
      //     name: "smrz",
      //     component: () => import("../../views/memberCenter/zhgl/smrz/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/user/certificateByCard",
      //     name: "certificateByCard",
      //     component: () =>
      //       import("../../views/memberCenter/zhgl/smrz/certificateByCard"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   {
      //     path: "/user/certificateByLisence",
      //     name: "certificateByLisence",
      //     component: () =>
      //       import("../../views/memberCenter/zhgl/smrz/certificateByLicense"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   {
      //     path: "/user/xgmm",
      //     name: "xgmm",
      //     component: () => import("../../views/memberCenter/zhgl/xgmm/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/user/xgzl",
      //     name: "xgzl",
      //     component: () => import("../../views/memberCenter/zhgl/xgzl/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/user/tgxxtj",
      //     name: "tgxxtj",
      //     component: () => import("../../views/memberCenter/sjtj/tgxxtj/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/user/zyzctj",
      //     name: "zyzctj",
      //     component: () => import("../../views/memberCenter/sjtj/zyzctj/index"),
      //     meta: {
      //       Author: true
      //     },
      //   },
      //   {
      //     path: "/user/sends",
      //     name: "sends",
      //     component: () => import("../../views/projectManagement/sendsInfo"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   {
      //     path: "/user/recommend",
      //     name: "recommend",
      //     component: () => import("../../views/projectManagement/recommend"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   //站内信息和我的订阅
      //   {
      //     path: "/user/myMessage",
      //     name: "myMessage",
      //     component: () => import("../../views/massageManagement/message"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   {
      //     path: "/user/mySubscription",
      //     name: "mySubscription",
      //     component: () => import("../../views/massageManagement/subscription"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   //缴款记录 paymentRecords
      //   {
      //     path: "/user/paymentRecords",
      //     name: "paymentRecords",
      //     component: () =>
      //       import("../../views/accountManagement/paymentRecords"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   //专业服务化
      //   {
      //     path: "/user/auditPlan",
      //     name: "auditPlan",
      //     component: () =>
      //       import("../../views/specializedService/auditPlan.vue"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   //上传规划条件
      //   {
      //     path: "/user/uploadFiles",
      //     name: "uploadFiles",
      //     component: () =>
      //       import("../../views/specializedService/uploadFiles.vue"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      //   //审核结果查询
      //   {
      //     path: "/user/auditResults",
      //     name: "auditResults",
      //     component: () =>
      //       import("../../views/specializedService/auditResults.vue"),
      //     meta: {
      //       Author: true,
      //     },
      //   },
      // ],
      // },
    ],
  },

  // 要删的页面--以下动态生成
  {
    path: "/memberCenter",
    name: "memberCenter",
    component: () => import("../../views/memberCenter/index"),
    redirect: "/memberCenter",
    children: [
      {
        path: "/memberCenter/snbt/chakan",
        name: "chakan",
        component: () =>
          import("../../views/memberCenter/snbt/snbtbl/components/chakan.vue"),
        meta: {
          Author: true,
        },
      },
      {
        path: "/memberCenter/snbt/chakan-c",
        name: "chakan-c",
        component: () =>
          import("../../views/memberCenter/snbt/snbtbl/components/chakan-c.vue"),
        meta: {
          Author: true,
        },
      },
      {
        path: "/memberCenter/snbt/btsb-c",
        name: "btsb-c",
        component: () =>
          import("../../views/memberCenter/snbt/snbtbl/components/btsb-c.vue"),
        meta: {
          Author: true,
        },
      },
      {
        path: "/memberCenter/snbt/btsb-chakan",
        name: "btsb-chakan",
        component: () =>
          import("../../views/memberCenter/snbt/snbtbl/components/btsb-chakan.vue"),
        meta: {
          Author: true,
        },
      },
      {
        path: "/memberCenter/snbt/btsb-z",
        name: "btsb-z",
        component: () =>
          import("../../views/memberCenter/snbt/snbtbl/components/btsb-z.vue"),
        meta: {
          Author: true,
        },
      },
      //种粮大户补贴项目展示
      {
        path: "/memberCenter/wdbtsq/components/grainSubsidy",
        name: "grainSubsidy",
        component: () =>
          import("../../views/memberCenter/snbt/wdbtsq/components/grainSubsidy.vue"),
        meta: {
          Author: true,
        },
      },
      //社会化服务补贴项目填写
      {
        path: "/memberCenter/wdbtsq/components/socialAllowance",
        name: "socialAllowance",
        component: () =>
          import("../../views/memberCenter/snbt/wdbtsq/components/socialAllowance.vue"),
        meta: {
          Author: true,
        },
      },
      // 农机购置补贴项目填写
      {
        path: "/memberCenter/wdbtsq/components/farmMachinery",
        name: "farmMachinery",
        component: () =>
          import("../../views/memberCenter/snbt/wdbtsq/components/farmMachinery.vue"),
        meta: {
          Author: true,
        },
      },
      //种粮大户补贴申报
      {
        path: "/memberCenter/snbt/snbtbl/components/subsidyApplication",
        name: "subsidyApplication",
        component: () =>
          import("../../views/memberCenter/snbt/snbtbl/components/subsidyApplication.vue"),
        meta: {
          Author: true,
        },
      },
      //种粮大户详情
      {
        path: "/memberCenter/snbt/snbtbl/components/subsidyApplicationDetails",
        name: "subsidyApplicationDetails",
        component: () =>
          import("../../views/memberCenter/snbt/snbtbl/components/subsidyApplicationDetails.vue"),
        meta: {
          Author: true,
        },
      },
      // 涉农价格补贴申报
      {
        path: "/memberCenter/snbt/snbtbl/components/subsidyList",
        name: "subsidyList",
        component: () =>
          import("../../views/memberCenter/snbt/snbtbl/components/subsidyList.vue"),
        meta: {
          Author: true,
        },
      },
      // 涉农价格补贴详情
      {
        path: "/memberCenter/snbt/snbtbl/components/subsidyListDetail",
        name: "subsidyListDetail",
        component: () =>
          import("../../views/memberCenter/snbt/snbtbl/components/subsidyListDetail.vue"),
        meta: {
          Author: true,
        },
      },
    ],
  },
];
